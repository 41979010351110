import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { AwesomeIcon } from 'ui';

export const VideoOverlay = ({
  tag: Tag,
  children,
  title = 'Virtuelle Tour Starten',
  icon = 'play',
  muted,
  showMuteButton = false,
  onClickToggleMute = () => {},
  className,
  ...props
}) => {
  const [t] = useTranslation(['laden', 'translations']);

  return (
    // add visible class to skip hover
    <Tag {...props} className={`ls-card ${className}`}>
      <div className="ls-media-overlay">
        <div className="row justify-center flex-align-center">
          <AwesomeIcon
            className="col-12"
            icon={icon}
            prefix="fac"
            size="3x"
            color="white"
          />
          <p
            className="white fw-bold large align-center fw uppercase p-1"
            style={{ lineHeight: 1 }}
          >
            <Trans t={t}>{title}</Trans>
          </p>
        </div>
      </div>
      {showMuteButton && (
        <div className="mute-btn">
          <AwesomeIcon
            onClick={onClickToggleMute}
            icon={muted ? 'volume-mute' : 'volume-up'}
            prefix="fa"
            size="xl"
            color="white"
          />
        </div>
      )}

      {children}
    </Tag>
  );
};

VideoOverlay.defaultProps = {
  tag: 'div',
};
