import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const Image = ({ src, alt, ...props }) => {
  return <img src={src} alt={alt} {...props} />;
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

Image.defaultProps = {
  alt: '',
};

export const Video = ({
  poster,
  src,
  type,
  sources,
  play,
  noPlay,
  children,
  ended,
  ...props
}) => {
  const ref = useRef(null);

  const source = sources
    ? sources.map(({ src, type }, i) => {
        return <source key={i} src={src} type={type} />;
      })
    : null;

  useEffect(() => {
    if (!ref?.current) return;
    const v = ref?.current;
    if (noPlay) return;
    if (play) v.play();
    if (!play) v.pause();

    v.addEventListener('ended', ended);

    return () => v.addEventListener('ended', ended);
  }, [play, noPlay]);

  return (
    <video
      ref={ref}
      poster={poster}
      src={src}
      type={type}
      {...props}
      playsInline
    >
      {source || children || null}
    </video>
  );
};

Video.propTypes = {
  // start pause video
  play: PropTypes.bool,
  poster: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({ src: PropTypes.string, type: PropTypes.string }),
  ),
  children: PropTypes.node,
};

export const Media = ({ tag: Tag, className, ...props }) => {
  const CustomTag = Tag === 'img' ? Image : Tag === 'video' ? Video : null;

  return <CustomTag className={`media ${className}`} {...props} />;
};

Media.propTypes = {
  className: PropTypes.string,
};

Media.defaultProps = {
  tag: 'img',
  src: null,
  className: '',
};
